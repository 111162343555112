import React, { useEffect, useState } from 'react'
import Layout from "../components/Layout";
import { backendHost } from "./dashboard";
import { HowItWorks } from "../components/HowItWorks";
import { EnterEmail } from "../components/EnterEmail";
import { Helmet } from "react-helmet";

export default function About ({ data }) {
  console.log(`data`, data);
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Itadise Team</title>
      </Helmet>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
          <div className="space-y-12">
            <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
              <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">{data.contentfulTeamPagina.title}</h2>
              <p className="text-xl text-gray-500">{data.contentfulTeamPagina.subtitle}</p>
              <p>{data.contentfulTeamPagina.mainText.mainText}</p>
              <h3 className="pt-16 text-xl font-extrabold tracking-tight sm:text-4xl">{data.contentfulTeamPagina.meetTeamTitle}</h3>
              <p className="text-xl text-gray-500">{data.contentfulTeamPagina.meetTeamSubtitle}</p>
            </div>
            <ul className="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:grid-cols-3 lg:max-w-5xl">
              {data.contentfulTeamPagina.teamMembers.map(obj => (
                <li key={obj.name}>
                  <div className="space-y-6">
                    <img className="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56" src={obj.picture.file.url} alt="" />
                    <div className="space-y-2">
                      <div className="text-lg leading-6 font-medium space-y-1">
                        <h3>{obj.name}</h3>
                        <p className="text-indigo-600">{obj.role}</p>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <EnterEmail />
    </Layout>
  )
};

export const query = graphql`
  query {
    contentfulTeamPagina {
      title
      subtitle
      mainText {
        mainText
      }
      meetTeamTitle
      meetTeamSubtitle
      teamMembers {
        name
        role
        picture {
          file {
            url
          }
        }
      }
    }
  }
`;